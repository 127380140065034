a.card {
  &:hover,
  &:focus {
    border-bottom-color: $card-border-color;
  }
}

.card-footer {
  border-top: 0;
}
