@mixin focus-outline($color) {
  a,
  .link,
  .text-link,
  .btn,
  button.btn,
  input[type="checkbox"] {
    &:focus {
      outline: 2px dotted $color;
      outline-offset: 5px;
    }
  }

  .btn-lg {
    &:focus {
      outline-offset: 8px;
    }
  }
}
