.logo {
  justify-self: start;
  margin-left: 10px;

  @include media-breakpoint-up(lg) {
    position: relative;
    margin-left: 20px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 40px;
  }

  img {
    height: 43px;

    @include media-breakpoint-up(sm) {
      height: 47px;
    }

    @include media-breakpoint-up(md) {
      height: 51px;
    }

    @include media-breakpoint-up(lg) {
      height: 65px;
    }
  }

  a {
    &,
    &:hover,
    &:active,
    &:focus {
      color: $primary;
      border: 0;
    }

    &:hover,
    &:active,
    &:focus {
      filter: brightness(80%);
    }
  }

  .icon {
    line-height: 0;
  }
}
