// scss-docs-start alert-variant-mixin
@mixin hero-variant($name, $bgcolor) {
  &.hero-section-#{$name} {
    background-color: $bgcolor;

    &::after {
      background-color: $bgcolor;

      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/hero/hero-#{$name}-mobile.jpg");

        @include media-breakpoint-up(md) {
          background-image: url("../images-opt/hero/hero-#{$name}-tablet.jpg");
        }

        @include media-breakpoint-up(xl) {
          background-image: url("../images-opt/hero/hero-#{$name}-desktop.jpg");
        }
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/hero/hero-#{$name}-mobile.webp");

        @include media-breakpoint-up(md) {
          background-image: url("../images-opt/hero/hero-#{$name}-tablet.webp");
        }

        @include media-breakpoint-up(xl) {
          background-image: url("../images-opt/hero/hero-#{$name}-desktop.webp");
        }
      }
    }
  }
}
