.logo-line {
  position: relative;
  overflow: hidden;
  height: 131px;
  color: $brand-success !important;
  margin-top: 53px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 61px;
    display: block;
    width: 50%;
    height: 14px;
    background: $brand-primary;
  }

  &::before {
    left: -72px;
  }

  &::after {
    right: -72px;
  }

  .logo-shape {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 132px;
    height: 131px;
    transform: translate(-50%, 0); // Pull it to the center position
    background-image: url("../images/logo-shape.svg");
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
}
