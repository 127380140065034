// Use this file to customize any Bootstrap SCSS variables + add your own.
// See: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

// Bootstrap defautls
$variable-prefix: bs-;

// Fonts
$font-family-sans-serif: Montserrat, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif: Vollkorn, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: var(--#{$variable-prefix}font-serif);
$font-family-headings: var(--#{$variable-prefix}font-sans-serif);
$font-family-code: var(--#{$variable-prefix}font-monospace);

$font-size-root: 18px;

$headings-font-family: $font-family-headings;
$headings-font-weight: 700;

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$white:                     #fff;
$gray-dark:                 #332c3f;
$gray:                      #55595c;
$gray-light:                #818a91;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f9;
$green:                     #64e184;
$green-dark:                #008823;
$violet:                    #783de0;

// Theme colors
$primary: $green;
$secondary: $violet;
$tertiary: $gray-dark;

$brand-primary:             $primary;
$brand-secondary:           $secondary;
$brand-tertiary:            $tertiary;
$brand-success:             $green;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;

$focus-outline:             #1c7e9b;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:        true;

// Links
//
// Style anchor elements.

$link-color:            $brand-tertiary;
$link-decoration:       none;
$link-hover-color:      lighten($link-color, 25%);
$link-hover-decoration: none;

// Components
//
// Define common padding and border radius sizes and more.

$component-active-color:      $white;
$component-active-bg:         $green-dark;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:         "Montserrat", Arial, sans-serif;
$font-family-sans-serif-lighter: "Montserrat", Arial, sans-serif;
$font-family-serif:              "Vollkorn", serif;
$font-family-monospace:          Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:               $font-family-serif;

$font-size-root: 16px;
$font-size-base: 1rem;

$paragraph-xl-font-size: 21px;
$paragraph-lg-font-size: 19px;
$paragraph-md-font-size: 18px;
$paragraph-sm-font-size: 17px;

$line-height-base:    1.75;
//$margin-bottom-base:  1.8rem;
$margin-bottom-base:  2.35rem;

$font-size-h1: 1.7rem;
$font-size-h2: 1.35rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

$headings-font-family:    $font-family-sans-serif;
$headings-line-height:    1.23;
$headings-line-height-h1: 1.33;
//$headings-margin-bottom:  2.45rem;
$headings-margin-bottom:  1.3rem;

//$blockquote-small-color:  $gray-light;
$blockquote-font-size:    $font-size-base * 2;
$blockquote-border-color: $brand-success;
//$blockquote-border-width: .25rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-font-family:       $font-family-sans-serif;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.
$btn-padding-x:                  1.32rem;
$btn-padding-y:                  .66rem;

$btn-padding-x-sm:               .5rem;
$btn-padding-y-sm:               .25rem;

$btn-padding-x-lg:               2.5rem;
$btn-padding-y-lg:               1.2rem;

$btn-font-weight:                700;

$btn-success-color:              $brand-primary;

$btn-border-radius:              0;
$btn-border-radius-lg:           0;
$btn-border-radius-sm:           0;

// Forms

$input-bg-inverted:              #181620;

$input-color-inverted:           #fff;

$input-border-radius:            0;
$input-border-radius-lg:         0;
$input-border-radius-sm:         0;

$input-border-focus:             $brand-success;
$input-box-shadow-focus:         none;

$input-padding-x-lg:             2rem;
$input-padding-y-lg:             1.25rem;

$form-group-margin-bottom:       .13rem;

$input-btn-border-width:         2px;

// Tags

$tag-secondary-bg:          $brand-secondary;

$tag-font-size:             68%;
$tag-font-weight:           normal;
$tag-padding-x:             .8em;
$tag-padding-y:             .8em;

// Cards

$card-cap-bg:               transparent;

// Modals

// Padding applied to the modal body
$modal-backdrop-opacity:      .75;
$modal-backdrop-opacity-dark: 1;
