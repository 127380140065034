.nav-container {
  position: relative;
  z-index: 2; // Keep it above the hero
  width: 100%;
  background-color: #fff;
  font-family: $font-family-sans-serif;
  font-weight: bold;

  @include media-breakpoint-up(lg) {
    margin-bottom: .5rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
  }

  .navbar {
    display: flex;
    height: 85px;

    @include media-breakpoint-up(lg) {
      height: 100px;
    }
  }

  .main-menu {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-right: 20px;

    @include media-breakpoint-up(md) {
      margin-right: 20px;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column-reverse;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      align-items: flex-start;
      margin-right: 40px;
    }
  }

  .nav-menu {
    position: absolute;
    display: grid;
    grid-template-columns: auto;
    top: 87px;
    left: -100%;
    opacity: 0;
    width: 100%;
    background: #fff;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    transition: all .5s ease;

    @include media-breakpoint-up(lg) {
      top: auto;
      left: auto;
      opacity: 1;
      position: relative;
      grid-template-columns: repeat(5, auto);
      background: transparent;
    }

    &.active {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all .4 ease-in-out;

      @include media-breakpoint-up(lg) {
        background: transparent;
      }

      li {
        border-bottom: 2px solid $gray-dark;

        @include media-breakpoint-up(lg) {
          border-bottom: 0;
        }
      }
    }

    li {
      font-family: $font-family-sans-serif;
      font-size: 19px;

      @include media-breakpoint-up(lg) {
        margin: 0 10px;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 15px;
      }

      a {
        color: #000;
        text-decoration: none;
        border-bottom: none;

        &:active,
        &:hover,
        &:focus,
        &.nav-selected,
        &.nav-path-selected {
          color: #858585;
          transition: all .3s ease-in-out;
        }
      }

      .active {
        color: #858585;
      }
    }
  }

  .menu-toggle {
    margin-left: 20px;
    align-self: center;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .bar {
      display: block;
      background: $gray-dark;
      width: 25px;
      height: 3px;
      margin: 5px auto;
      transition: all .3s ease-in-out;
    }
  }

  .language-menu {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: bold;

    @include media-breakpoint-up(lg) {
      margin-left: 10px;
      margin-right: 20px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 40px;
      margin-right: 0;
    }

    a {
      background: $black;
      color: $white;
      display: inline-block;
      text-align: center;
      width: 32px;
      height: 32px;
      line-height: 35px;
      font-size: .8rem;
      font-weight: bold;
      margin-left: 8px;
      border-bottom: none;

      &.active {
        background: $brand-primary;
        color: $black;
      }
    }
  }

  .nav-links {
    text-align: center;
    line-height: 60px;

    @include media-breakpoint-up(lg) {
      text-align: left;
      line-height: inherit;
    }

    a {
      &:hover {
        color: #858585;
        transition: all .4s eas-out;
      }
    }
  }

  #mobile-menu {
    transform: translate(15%, 20%);

    &.is-active {
      .bar {
        &:nth-child(1) {
          transform: translateY(8px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }
}
