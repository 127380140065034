.background-section {
  color: #fff;
  background-color: #332c3f;

  &.index-contact {
    i.fa-envelope {
      font-size: 20px;
      padding-right: 10px;
    }

    a.info-email {
      color: #fff;
      border-bottom: none;

      &:hover,
      &:active,
      &:focus {
        border-bottom: 2px solid #fff;
      }
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.section {
  padding: 10px 0;
  line-height: 1.63;

  @include media-breakpoint-up(sm) {
    padding: 50px 0;
  }

  @include media-breakpoint-up(md) {
    padding: 53px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 58px 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 70px 0;
  }

  &.section-intro {
    p {
      font-family: $font-family-sans-serif;
      font-size: 20px;

      @include media-breakpoint-up(sm) {
        font-size: 25px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 31px;
      }
    }
  }

  &.section-subhero {
    padding: 50px 0;
    line-height: 1.63;

    @include media-breakpoint-up(md) {
      padding: 53px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 58px 0;
    }

    @include media-breakpoint-up(xl) {
      padding: 70px 0;
    }
  }

  &.section-items-contents,
  &.section-about,
  &.section-legal,
  &.section-cookies,
  &.section-service-participation-consultation,
  &.section-article {
    .fa-building,
    .fa-user-tie,
    .fa-envelope-open-text,
    .fa-file-invoice {
      font-size: 70px;
    }

    .contact-card {
      border: 1px solid #e7e9ed;
    }

    @include media-breakpoint-up(xs) {
      .contact-address {
        text-align: center;
      }
    }

    @include media-breakpoint-up(sm) {
      font-size: $paragraph-sm-font-size;
    }

    @include media-breakpoint-up(md) {
      font-size: $paragraph-md-font-size;
    }

    @include media-breakpoint-up(lg) {
      font-size: $paragraph-lg-font-size;

      .contact-address {
        text-align: left;
      }
    }

    @include media-breakpoint-up(xl) {
      font-size: $paragraph-xl-font-size;

      .contact-address {
        padding-left: 70px;
      }
    }
  }

  &.section-service-participation-consultation {
    .btn {
      min-width: 220px;
    }
  }

  &.without-top-padding {
    padding-top: 0;
  }

  &.without-bottom-padding {
    padding-bottom: 0;
  }

  p {
    margin-bottom: 1.95rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  .h1,
  h2,
  .h2 {
    margin-top: 0;
    margin-bottom: 3.2rem;
  }

  .section-title {
    margin-bottom: 3.2rem;

    h2,
    .h2 {
      display: inline;
      border-bottom: 5px solid $brand-success;
      line-height: 1.45;
    }
  }

  .btn {
    min-width: 150px;

    &.btn-outline-dark:focus {
      box-shadow: none;
      color: #fff;
      background-color: #212529;
      border-color: #212529;
    }
  }

  .section-columns-wrapper {
    padding-top: 16px;

    .section-columns-right {
      margin-top: 50px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }

  .content-figure {
    i {
      color: $brand-success;
      font-size: 400px;
    }
  }
}

.lead-section {
  margin-bottom: 72px;

  p {
    font-size: $paragraph-sm-font-size;
    text-align: center;

    @include media-breakpoint-up(sm) {
      font-size: $paragraph-lg-font-size;
    }

    @include media-breakpoint-up(xl) {
      font-size: $paragraph-xl-font-size;
    }
  }
}

.cta {
  img {
    width: 50%;
    object-fit: cover;
  }
}

.wwd {
  font-size: 25px;
  font-weight: 400;
}

.reference-index {
  img {
    width: 70%;
    object-fit: cover;
  }

  p {
    margin-bottom: 0;
  }
}

.reference-main {
  .icon-xl {
    font-size: 5rem;
    border-bottom: none;
    transition: all .2s ease-in-out 0s;

    &:hover {
      transform: translateY(-5px);
    }

    @include media-breakpoint-up(xs) {
      margin-right: 10px;
    }

    @include media-breakpoint-up(sm) {
      margin-right: 40px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 30px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 50px;
    }
  }

  img {
    width: 150px;
  }
}

.services {
  a {
    display: block;
    border-bottom: 0;
  }

  .fa-users,
  .fa-laptop {
    @include media-breakpoint-up(xs) {
      font-size: 50px;
    }

    @include media-breakpoint-up(sm) {
      font-size: 80px;
    }

    @include media-breakpoint-up(md) {
      font-size: 120px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 100px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 120px;
    }
  }
}

.case-study-section {
  &.over-curve {
    position: relative;
    z-index: 10;
  }

  .item {
    min-height: 100%;
  }

  .section-title {
    margin-bottom: 3.2rem;

    h2,
    .h2 {
      display: inline;
      border-bottom: 5px solid $brand-success;
      line-height: 1.45;
    }
  }

  .figure-holder {
    height: 100%;
    min-height: 325px;
    position: relative;
    background-size: cover;

    &.figure-holder-helsinki-os {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/omastadi.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/omastadi.webp");
      }
    }

    &.figure-holder-helsinki-ip {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/helsinki-idea-roastery.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/helsinki-idea-roastery.webp");
      }
    }

    &.figure-holder-hameenlinna-vau {
      background-color: #000;
    }
  }

  .case-logo {
    max-width: 120px;

    &.hameenlinna-vau {
      max-width: 200px;
    }
  }

  a.more-link {
    text-decoration: none;
    border-bottom: none;
    color: $gray;

    &:hover,
    &:focus,
    &:active {
      color: $gray-dark;
    }
  }
}

.case-section {
  &.over-curve {
    position: relative;
    z-index: 10;
  }

  .item {
    min-height: 100%;
    transition: all .2s ease-in-out 0s;
  }

  a {
    &:hover {
      .item {
        transform: translateY(-5px);
      }
    }
  }

  .section-title {
    margin-bottom: 3.2rem;

    h2,
    .h2 {
      display: inline;
      border-bottom: 5px solid $brand-success;
      line-height: 1.45;
    }
  }

  .figure-holder {
    height: 100%;
    min-height: 200px;
    position: relative;
    background-size: cover;

    &.figure-holder-helsinki-os {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/omastadi.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/omastadi.webp");
      }
    }

    &.figure-holder-tuusula {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/tuusula.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/tuusula.webp");
      }
    }

    &.figure-holder-vantaa {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/vantaa.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/vantaa.webp");
      }
    }

    &.figure-holder-turku {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/turku.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/turku.webp");
      }
    }

    &.figure-holder-tampere {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/tampere.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/tampere.webp");
      }
    }

    &.figure-holder-riihimaki {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/riihimaki.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/riihimaki.webp");
      }
    }

    &.figure-holder-oulu {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/oulu.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/oulu.webp");
      }
    }

    &.figure-holder-helsinki-kk {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/helsinki_experimentation_accelerator.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/helsinki_experimentation_accelerator.webp");
      }
    }

    &.figure-holder-seinajoki {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/seinajoki.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/seinajoki.webp");
      }
    }

    &.figure-holder-siilinjarvi {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/siilinjarvi.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/siilinjarvi.webp");
      }
    }

    &.figure-holder-helsinki-ip {
      @at-root .support-webp-0 #{&} {
        background-image: url("../images-opt/references/helsinki-idea-roastery.jpg");
      }

      @at-root .support-webp-1 #{&} {
        background-image: url("../images-opt/references/helsinki-idea-roastery.webp");
      }
    }

    &.figure-holder-hameenlinna-vau {
      background-color: #000;
    }
  }

  .case-logo {
    max-width: 120px;

    &.hameenlinna-vau {
      max-width: 200px;
    }
  }

  a.more-link {
    text-decoration: none;
    border-bottom: none;
    color: $gray;

    &:hover,
    &:focus,
    &:active {
      color: $gray-dark;
    }
  }
}

.decidim-advantages,
.decidim-features,
.public-sector-services,
.organisations-services,
.digital-participation-more {
  .card-body {
    .fas {
      font-size: 30px;
    }
  }
}

.decidim-features {
  .feature-image {
    img {
      max-width: 100%;

      @include media-breakpoint-up(md) {
        max-width: 75%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 50%;
      }
    }
  }
}

.digital-participation-more,
.public-sector-services,
.organisations-services {
  .card-text {
    font-size: 1rem;
  }
}

.organisations-services {
  padding: 0;
}

.light-background {
  background-color: #f5f6fe;

  h1,
  .h1,
  h2,
  .h2 {
    margin-top: 0;
    margin-bottom: 3.2rem;
  }

  .section-title {
    margin-bottom: 3.2rem;

    h2,
    .h2 {
      display: inline;
      border-bottom: 5px solid $brand-success;
      line-height: 1.45;
    }
  }

  .card-body {
    .fas {
      font-size: 30px;
    }
  }
}
