a {
  border-bottom: 2px solid $link-color;

  &:hover,
  &:focus {
    color: $link-hover-color;
    border-bottom-color: $link-hover-color;
  }
}

p,
ol,
ul,
dl {
  margin-bottom: $margin-bottom-base;
}

h1,
.h1 {
  text-align: center;
  font-size: 40px;
  line-height: $headings-line-height-h1;

  @include media-breakpoint-up(sm) {
    font-size: 50px;
  }

  @include media-breakpoint-up(md) {
    font-size: 60px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 70px;
  }
}

h2,
.h2 {
  margin-top: 3.3rem;
  font-size: 27px;

  @include media-breakpoint-up(md) {
    font-size: 32px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 37px;
  }
}

h3,
.h3 {
  margin-top: 3.3rem;
  font-size: 18px;

  @include media-breakpoint-up(md) {
    font-size: 21px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 25px;
  }
}

h4,
.h4 {
  font-size: 18px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 22px;
  }
}

.page-title {
  margin-bottom: 2.9em;

  h1,
  .h1 {
    display: inline;
    border-bottom: 5px solid $brand-success;
    margin-top: 0;
    padding: 0 2px 1px;
  }
}

.back-link {
  @extend .h2;

  font-weight: normal;
  text-decoration: none;

  a {
    border-bottom: 0;
  }
}

.details-list {
  margin-bottom: 30px;
  font-size: 16px;
  padding-left: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: 53px;
  }

  .details-list-item {
    display: block;

    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 5px;
    }

    &::before {
      @include media-breakpoint-up(md) {
        display: inline-block;
        content: "|";
        margin: 0 4px 0 0;
        color: #5e5a6d;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 12px 0 3px;
      }
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    p,
    a,
    span {
      display: inline-block;
      font-size: 16px;
    }
  }
}

//
// Misc
//

// Blockquotes
.blockquote {
  position: relative;
  z-index: 0;
  margin-bottom: 1.8rem;
  padding: 1rem 4rem;
  font-style: italic;
  text-align: center;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    color: $gray-500;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }

  &::before {
    content: "#{$fa-var-quote-left}";
    top: 0;
    left: 10px;
  }

  &::after {
    content: "#{$fa-var-quote-right}";
    bottom: 0;
    right: 10px;
  }
}
