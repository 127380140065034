.hero-section {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #fff;

  @include media-breakpoint-up(md) {
    display: flex;
    min-height: 480px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 590px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 620px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 130%; // Give a bit more height to play with the bottom positioning
    background-color: #ababab;
    background-size: 150% auto;
    background-repeat: no-repeat;
    background-position: center top;

    @media (min-width: 500px) {
      background-size: 767px auto;
    }

    @include media-breakpoint-up(md) {
      top: auto;
      bottom: -6%;
      background-size: 165% auto;
      background-position: center bottom;
    }

    @include media-breakpoint-up(lg) {
      background-size: 170% auto;
      bottom: -18%;
    }

    @include media-breakpoint-up(xl) {
      bottom: -16%;
      background-size: 200% auto;
    }

    @include media-breakpoint-up(xxl) {
      bottom: -18%;
      background-size: 180% auto;
    }

    @media (min-width: 1685px) {
      background-size: 2970px auto;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 70%;

    @media (min-width: 500px) {
      padding-bottom: 350px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  @include hero-variant(home, #5a3f91);
  @include hero-variant(services, #7a3686);
  @include hero-variant(references, #0d5d82);
  @include hero-variant(decidim, #943849);
  @include hero-variant(contact, #6f8bc5);

  // Customize the focus outline so that it is visible enough
  @include focus-outline(lighten($focus-outline, 40%));

  .hero-content {
    position: relative;
    z-index: 1;
    padding: 2rem 0 3rem;

    @include media-breakpoint-up(md) {
      width: 100%;
      padding: 5rem 0;
    }

    .lead {
      *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
