.icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Site Icons" !important;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@import "../icons/variables";
@import "../icons/icons";
