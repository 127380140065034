footer,
.prefooter {
  // Customize the focus outline so that it is visible enough
  @include focus-outline(lighten($focus-outline, 40%));

  .form-control {
    &:focus {
      border-color: #00ff54;
    }
  }
}

footer {
  background-color: $gray-dark;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  padding: 54px 0 5px;

  a {
    color: #fff;
    text-decoration: underline;

    &:focus,
    &:hover,
    &:active {
      color: #d9d9d9;
      text-decoration: underline;
      border-bottom: 0;
    }
  }

  .footer-logos {
    position: relative;
    top: 0;
    padding: 30px 0 20px;
    font-size: 49px;
    line-height: .8;

    @include media-breakpoint-up(xs) {
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      font-size: 40px;
    }

    @include media-breakpoint-up(lg) {
      top: -11px;
      float: right;
      font-size: 49px;
    }

    a {
      &:focus,
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  .footer-bottom {
    @include media-breakpoint-up(xs) {
      margin-top: 50px;
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 20px;
      text-align: left;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }
}
